var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Input Data Pembayaran ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"No Invoice","label-for":"noinvoice"}},[_c('validation-provider',{attrs:{"name":"noinvoice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"noinvoice","state":errors.length > 0 ? false : null,"placeholder":"No Invoice","disabled":""},model:{value:(_vm.dataStudent.noinvoice),callback:function ($$v) {_vm.$set(_vm.dataStudent, "noinvoice", $$v)},expression:"dataStudent.noinvoice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"NISN","label-for":"nisn"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nisn","state":errors.length > 0 ? false : null,"placeholder":"NISN","disabled":""},model:{value:(_vm.dataStudent.nisn),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nisn", $$v)},expression:"dataStudent.nisn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama","label-for":"nama_siswa"}},[_c('validation-provider',{attrs:{"name":"nama_siswa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nama_siswa","state":errors.length > 0 ? false : null,"placeholder":"nama_siswa","disabled":""},model:{value:(_vm.dataStudent.nama_siswa),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nama_siswa", $$v)},expression:"dataStudent.nama_siswa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Tagihan","label-for":"jenis_bayar"}},[_c('validation-provider',{attrs:{"name":"jenis_bayar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"jenis_bayar","state":errors.length > 0 ? false : null,"placeholder":"Nama Tagihan","disabled":""},model:{value:(_vm.dataStudent.jenis_bayar),callback:function ($$v) {_vm.$set(_vm.dataStudent, "jenis_bayar", $$v)},expression:"dataStudent.jenis_bayar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Total Tagihan","label-for":"nominal_gen"}},[_c('validation-provider',{attrs:{"name":"tagihan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tagihan","state":errors.length > 0 ? false : null,"placeholder":"Total Tagihan","disabled":""},model:{value:(_vm.dataStudent.tagihan),callback:function ($$v) {_vm.$set(_vm.dataStudent, "tagihan", $$v)},expression:"dataStudent.tagihan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tahun Akademik","label-for":"academic_year"}},[_c('validation-provider',{attrs:{"name":"academic_year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"academic_year","state":errors.length > 0 ? false : null,"placeholder":"Tahun Akademik","disabled":""},model:{value:(_vm.dataStudent.academic_year),callback:function ($$v) {_vm.$set(_vm.dataStudent, "academic_year", $$v)},expression:"dataStudent.academic_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Periode Tagihan","label-for":"periode_gen"}},[_c('validation-provider',{attrs:{"name":"periode_gen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"periode_gen","state":errors.length > 0 ? false : null,"placeholder":"Periode Tagihan","disabled":""},model:{value:(_vm.dataStudent.periode_month),callback:function ($$v) {_vm.$set(_vm.dataStudent, "periode_month", $$v)},expression:"dataStudent.periode_month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Pembayaran","label-for":"nominal_bayar"}},[_c('validation-provider',{attrs:{"name":"nominal_bayar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nominal_bayar","state":errors.length > 0 ? false : null,"placeholder":"Pembayaran"},model:{value:(_vm.dataStudent.nominal_bayar),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nominal_bayar", $$v)},expression:"dataStudent.nominal_bayar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Potongan","label-for":"potongan"}},[_c('validation-provider',{attrs:{"name":"potongan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"potongan","state":errors.length > 0 ? false : null,"placeholder":"Potongan"},model:{value:(_vm.dataStudent.potongan),callback:function ($$v) {_vm.$set(_vm.dataStudent, "potongan", $$v)},expression:"dataStudent.potongan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"metode_bayar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Metode Pembayaran","label-for":"metode_bayar","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"metode_bayar","reduce":function (is_active) { return is_active.value; },"placeholder":"Pilih Metode Pembayaran","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataIsActive,"label":"text"},model:{value:(_vm.dataStudent.metode_bayar),callback:function ($$v) {_vm.$set(_vm.dataStudent, "metode_bayar", $$v)},expression:"dataStudent.metode_bayar"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"keterangan"}},[_c('validation-provider',{attrs:{"name":"keterangan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"keterangan","state":errors.length > 0 ? false : null,"placeholder":"keterangan"},model:{value:(_vm.dataStudent.keterangan),callback:function ($$v) {_vm.$set(_vm.dataStudent, "keterangan", $$v)},expression:"dataStudent.keterangan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Bukti Pembayaran","label-for":"files","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"file"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"file","state":errors.length > 0 ? false : null,"accept":".png, .jpg"},model:{value:(_vm.databody.file),callback:function ($$v) {_vm.$set(_vm.databody, "file", $$v)},expression:"databody.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }