<template>
  <b-card>
    <h4 class="mb-0">
      Input Data Pembayaran
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <b-form-group label="No Invoice" label-for="noinvoice">
              <validation-provider #default="{ errors }" name="noinvoice" rules="required">
                <b-form-input id="noinvoice" v-model="dataStudent.noinvoice" :state="errors.length > 0 ? false : null"
                  placeholder="No Invoice" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="NISN" label-for="nisn">
              <validation-provider #default="{ errors }" name="NIP" rules="required">
                <b-form-input id="nisn" v-model="dataStudent.nisn" :state="errors.length > 0 ? false : null"
                  placeholder="NISN" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nama" label-for="nama_siswa">
              <validation-provider #default="{ errors }" name="nama_siswa" rules="required">
                <b-form-input id="nama_siswa" v-model="dataStudent.nama_siswa" :state="errors.length > 0 ? false : null"
                  placeholder="nama_siswa" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nama Tagihan" label-for="jenis_bayar">
              <validation-provider #default="{ errors }" name="jenis_bayar" rules="required">
                <b-form-input id="jenis_bayar" v-model="dataStudent.jenis_bayar" :state="errors.length > 0 ? false : null"
                  placeholder="Nama Tagihan" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Total Tagihan" label-for="nominal_gen">
              <validation-provider #default="{ errors }" name="tagihan" rules="required">
                <b-form-input id="tagihan" v-model="dataStudent.tagihan" :state="errors.length > 0 ? false : null"
                  placeholder="Total Tagihan" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tahun Akademik" label-for="academic_year">
              <validation-provider #default="{ errors }" name="academic_year" rules="required">
                <b-form-input id="academic_year" v-model="dataStudent.academic_year"
                  :state="errors.length > 0 ? false : null" placeholder="Tahun Akademik" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Periode Tagihan" label-for="periode_gen">
              <validation-provider #default="{ errors }" name="periode_gen" rules="required">
                <b-form-input id="periode_gen" v-model="dataStudent.periode_month"
                  :state="errors.length > 0 ? false : null" placeholder="Periode Tagihan" disabled />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Pembayaran" label-for="nominal_bayar">
              <validation-provider #default="{ errors }" name="nominal_bayar" rules="required">
                <b-form-input id="nominal_bayar" v-model="dataStudent.nominal_bayar"
                  :state="errors.length > 0 ? false : null" placeholder="Pembayaran" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Potongan" label-for="potongan">
              <validation-provider #default="{ errors }" name="potongan" rules="required">
                <b-form-input id="potongan" v-model="dataStudent.potongan" :state="errors.length > 0 ? false : null"
                  placeholder="Potongan" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="metode_bayar" rules="required">
              <b-form-group label="Metode Pembayaran" label-for="metode_bayar" :state="errors.length > 0 ? false : null">
                <v-select id="metode_bayar" v-model="dataStudent.metode_bayar" :reduce="(is_active) => is_active.value"
                  placeholder="Pilih Metode Pembayaran" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dataIsActive" label="text" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>            
            <b-form-group label="Keterangan" label-for="keterangan">
              <validation-provider #default="{ errors }" name="keterangan">
                <b-form-input id="keterangan" v-model="dataStudent.keterangan" :state="errors.length > 0 ? false : null"
                  placeholder="keterangan" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Bukti Pembayaran" label-for="files" label-cols-md="4">
                <validation-provider #default="{ errors }" name="file">
                  <b-form-file id="file" v-model="databody.file" :state="errors.length > 0 ? false : null"
                    accept=".png, .jpg"  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    name: '',
    dataStudent: {
      noinvoice: '',
      id: '',
      nisn: '',
      nama_siswa: '',
      jenis_bayar: '',
      academic_year_id: '',
      class_id: '',
      periode: '',
      tagihan: 0,
      nominal_bayar: 0,
      potongan: 0,
      metode_bayar: '',
      tgl_periode: '',
      academic_year: '',
      periode_month: '',
      keterangan: '',
    },
    databody: {        
        file: '',
      },
    dataIsActive: [
      { value: 'Cash', text: 'CASH' },
      { value: 'Non Cash', text: 'NON CASH' },
    ],

    selectedFile: null,
    base64String: null,
    decodedResult: null,
    binaryData: null,
  }),
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail()
  },
  methods: {
    handleFileChange() {

    },
    stringToBinary(string) {
      const binaryData = [];
      for (let i = 0; i < string.length; i++) {
        binaryData.push(string.charCodeAt(i).toString(2).padStart(8, '0'));
      }
      return binaryData.join(' ');
    },
    generateAutoNumber() {
      const dateTimeStamp = new Date().getTime(); // Get current timestamp
      const randomNum = Math.floor(Math.random() * 1000); // Generate a random number

      // Combine timestamp and random number to create the auto number
      const autoNumber = `${dateTimeStamp}-${randomNum}`;

      // Prepend "INV-" to the auto number
      this.autoNumber = `INV-${autoNumber}`;
      return this.autoNumber;
    },
    getCurrentDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
      const day = String(now.getDate()).padStart(2, '0'); // Add leading zero if needed

      // Format the date as 'yyyy-mm-dd'
      this.currentDate = `${year}-${month}-${day}`;
      return this.currentDate;
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/paymentrecaps/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    formatDate(datevalue) {
      const date = new Date(datevalue);
      const options = { year: 'numeric', month: 'long' };
      return this.formattedDate = date.toLocaleString('en-US', options);
    },
    fillStudentData(data) {
      this.dataStudent.noinvoice = this.generateAutoNumber();
      this.dataStudent.id = data.id
      this.dataStudent.nisn = data.nisn
      this.dataStudent.tagihan = data.nominal_gen
      this.dataStudent.jenis_bayar = data.nama_pembayaran
      this.dataStudent.nama_siswa = data.siswa.name
      this.dataStudent.academic_year_id = data.academic_year_id
      this.dataStudent.class_id = data.kelas
      this.dataStudent.periode = data.periode_gen
      this.dataStudent.potongan = data.potongan_gen
      this.dataStudent.tgl_periode = this.getCurrentDate();
      this.dataStudent.nominal_bayar = data.nominal_gen
      this.dataStudent.academic_year = data.tahun.name
      this.dataStudent.periode_month = this.formatDate(data.periode_gen)
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const invoicenumber = this.generateAutoNumber();
            const periode_date = this.getCurrentDate();
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            dataFormStudent.append('status_bayar', 'PAID')
            await this.$http.post(`/paymentschools`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Pembayaran Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            const dataFormBukti = new FormData()
            Object.keys(this.databody).forEach(key => {
              dataFormBukti.append(key, this.databody[key])
            })
            var dynamicUrl = 'uploadbuktipembayarans';
            dataFormBukti.append('buktipembayaran[]', this.databody.file)
            dataFormBukti.append('noinvoice', this.dataStudent.noinvoice)
            const response = await this.$http.post(dynamicUrl, dataFormBukti)            
            this.$router.push({ name: 'pembayaran-lainnya' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Pembayaran Gagal',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
